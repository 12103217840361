import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, ScrollView, FlatList, TouchableOpacity } from 'react-native';
import type { ArticleFeedProps, ArticleProps, BEEventProps, BracketCompetitionProps, BracketProps, CompanyProps, CompetitionProps, CompetitionResultTypeProps, CompetitionTypeProps, EventProps, LeagueProps, PodcastProps, PublicPlayerProps, SquaresCompetitionProps } from '../../types';
import type { CompanyMemberProps } from '../../Authenticator/api/types';
import { CompanyProfileApi, CompanyProfileHelpers } from './api';
import Colors from '../../constants/colors';
import { Icons, Text } from '../../Components';
import { view_styles } from '../../constants/styles';
import PlayerCard from '../PlayerCard';
import CompetitionCard from '../../Engage/components/CompetitionCard';
import BracketCompetitionCard from '../../Engage/components/BracketCompetitionCard';
import SquaresCompetitionCard from '../../Engage/components/SquaresCompetitionCard';
import ArticleCard from '../ArticleCard';
import PodcastCard from '../PodcastCard';
import moment from 'moment-mini';

type CompanyProfileProps = {
    company_id:string,
    padding_insets?:{ top:number, bottom:number },
    onSelectCompetition:(c:CompetitionProps) => void,
    onSelectBracketCompetition:(bc:BracketCompetitionProps) => void,
    onSelectSquaresCompetition:(sc:SquaresCompetitionProps) => void,
    onArticleSelect:(article:ArticleProps) => void,
    onEvent?:(be_event:BEEventProps) => void,
    onSelectPodcast:(pod:PodcastProps) => void,
    onSelectPlayer:(player:PublicPlayerProps) => void,
    onBack?:() => void,
    onShareCompany?:(company:CompanyProps) => void
}
const CompanyProfile = ({ company_id, padding_insets, onSelectCompetition, onSelectBracketCompetition, onSelectSquaresCompetition, onArticleSelect, onEvent, onSelectPodcast, onSelectPlayer, onBack, onShareCompany }:CompanyProfileProps) => {
    const [ module_size, setModuleSize ] = useState({ height:700, width:300 });
    const [ podcast_data, setPodcastData ] = useState<{
        podcast_loading:boolean,
        podcasts:PodcastProps[]
    }>({
        podcast_loading:false,
        podcasts:[]
    });
    const { podcasts } = podcast_data;

    const [ company_data, setCompanyData ] = useState<{
        company_loading:boolean
        active_toggle:string,
        company?:CompanyProps,
        company_members:CompanyMemberProps[],
        players:PublicPlayerProps[]
    }>({
        company_loading: false,
        company_members: [],
        players:[],
        active_toggle: 'Comps'
    });
    const { company_loading, company_members, company, players, active_toggle } = company_data;

    const [ competition_data, setCompetitionData ] = useState<{
        competition_loading:boolean,
        competition_types:CompetitionTypeProps[],
        leagues:LeagueProps[],
        brackets:BracketProps[],
        competition_result_types:CompetitionResultTypeProps[],
        competitions:CompetitionProps[],
        events:EventProps[],
        bracket_competitions:BracketCompetitionProps[],
        squares_competitions:SquaresCompetitionProps[],
        offset:number
    }>({
        competition_loading: false,
        offset: 0,
        competitions:[],
        leagues:[],
        competition_types:[],
        brackets: [],
        events:[],
        competition_result_types:[],
        bracket_competitions:[],
        squares_competitions: [],
    })
    const { competition_loading, competitions, bracket_competitions, brackets, squares_competitions, events, competition_result_types, competition_types, leagues } = competition_data;
    const sorted_comps = CompanyProfileHelpers.sortComps(competitions, bracket_competitions, squares_competitions);

    const [ feed_data, setFeedData ] = useState<{
        feed_loading:boolean,
        article_feeds:ArticleFeedProps[],
        articles:ArticleProps[],
        feed_offset:number
    }>({
        feed_loading: false,
        article_feeds:[],
        articles:[],
        feed_offset:0
    });
    const { feed_loading, article_feeds, articles } = feed_data;

    useEffect(() => {
        CompanyProfileApi.setEnvironment();
        if(!company_id){ return }
        getCompanyDataFromServer(company_id);
        getPodcastsFromServer(company_id, 0);
    },[company_id]);

    useEffect(() => {
        switch(active_toggle){
            case 'Comps':
                getCompsFromServer(company_id, 0);
                return
            case 'Feeds':
                getFeedFromServer(company_id, 0);
                return
            default: return
        }
    },[active_toggle]);

    const getPodcastsFromServer = async(id:string, offset:number) => {
        setPodcastData({ ...podcast_data, podcast_loading:true });
        const ps = await CompanyProfileApi.getPodcastsByCompanyId(id, offset);
        setPodcastData({
            ...podcast_data,
            podcast_loading: false,
            podcasts: ps
        })
    }

    const getFeedFromServer = async(id:string, offset:number) => {
        setFeedData({ ...feed_data, feed_loading:true });
        const feeds = await CompanyProfileApi.getArticleFeedsByCompanyId(id);
        const arts = await CompanyProfileApi.getArticlesByCompanyId(id, offset);
        setFeedData({
            ...feed_data,
            feed_loading: false,
            article_feeds: feeds,
            articles: arts,
            feed_offset:offset
        })
    }

    const getCompsFromServer = async(id:string, offset:number) => {
        setCompetitionData({ ...competition_data, competition_loading: true });
        const comps = await CompanyProfileApi.getCompetitionsByCompanyId(id, offset, 'active');
        const bcs = await CompanyProfileApi.getBracketCompetitionsByCompanyId(id, offset, 'active');
        const scs = await CompanyProfileApi.getSquaresCompsByCompanyId(id, 'active');
        const opts = await CompanyProfileApi.getCompetitionOptions();
        const lgs = await CompanyProfileApi.getLeagues();
        if(!opts){ return alert('Unable to get competition details') }
        const bks = await CompanyProfileApi.getBracketsByIds(bcs.map(bc => bc.bracket_id));
        const es = await CompanyProfileApi.getEventsByEventIds(scs.map(sc => sc.event_id));
        setCompetitionData({ 
            ...competition_data,
            competition_loading: false,
            competitions:comps.filter(c => !c.invite_only),
            bracket_competitions: bcs,
            squares_competitions: scs,
            leagues:lgs,
            brackets: bks,
            competition_result_types: opts.competition_result_types,
            events: es,
            competition_types: opts.competition_types,
            offset
        })
    }

    const getCompanyDataFromServer = async(id:string) => {
        if(onEvent){
            onEvent({
                event_name:'company_view',
                event_data: {
                    company_id:id,
                    view_type:'profile',
                    view_location: 'profile'
                },
                level:2
            })
        }

        setCompanyData({ ...company_data, company_loading:true });
        const c_resp = await CompanyProfileApi.getCompanyById(id);
        if(!c_resp){ return alert('Unable to get company details.  Please try again later') }
        let ps = await CompanyProfileApi.getPlayersByPlayerIds(c_resp.company_members.map(cm => cm.player_id))
        setCompanyData({
            ...company_data,
            company_loading:false,
            company: c_resp.company,
            company_members: c_resp.company_members,
            players: ps,
        });
    }

    const renderMembers = (data: { item:CompanyMemberProps, index:number }) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        if(!player){ return <></> }
        return (
            <View style={{ padding:4 }}>
                <PlayerCard
                    player={player}
                    width={120}
                    border
                    alignment='vertical'
                    onSelectPlayer={onSelectPlayer}
                    onFollowingUpdate={(pf) => console.log(pf)}
                />
            </View>
        )
    }

    const renderArticles = (data:{ item:ArticleProps, index:number }) => {
        const article_feed = article_feeds.find(af => af.article_feed_id == data.item.article_feed_id);
        let article_width = (module_size.width - 60) / 2
        if(article_width > 240){ article_width = 240 }
        return (
            <View style={{ width:article_width, margin:5 }}>
                <ArticleCard
                    article_feed={article_feed}
                    article={data.item}
                    width={article_width}
                    onSelectArticle={onArticleSelect}
                    onEvent={onEvent}
                />
            </View>
        )
    }

    const renderPodcasts = (data: { item:PodcastProps }) => {
        let pod_width = (module_size.width) / 3
        if(pod_width > 200){ pod_width = 200 }
        return (
            <View style={{  }}>
                <PodcastCard
                    podcast={data.item}
                    width={pod_width}
                    onSelectPodcast={onSelectPodcast}

                />
            </View>
        )
    }

    const renderToggles = (data:{ item:string, index:number }) => {
        const active = data.item == active_toggle ? true : false
        return (
            <TouchableOpacity style={{ width: (module_size.width - 65) / 2, padding:15, borderRadius:22, backgroundColor:active?Colors.brand.midnight:'transparent' }}
            onPress={() => setCompanyData({ ...company_data, active_toggle: data.item })}>
                <Text textAlign='center' weight='bold' size={14} color={active ?Colors.shades.white:Colors.brand.midnight}>{data.item}</Text>
            </TouchableOpacity>
        )
    }

    const renderComps = (data: { item:{ type: 'competition'|'squares_competition'|'bracket_competition', id:string, start_time:any }, index:number }) => {
        switch(data.item.type){
            case 'competition':
                const competition = competitions.find(c => c.competition_id == data.item.id);
                if(!competition){ return <></> }
                const competition_type = competition_types.find(ct => ct.competition_type_id == competition.competition_type_id);
                if(!competition_type){ return <></> }
                const competition_result_type = competition_result_types.find(ct => ct.competition_result_type_id == competition.competition_result_type_id);
                if(!competition_result_type){ return <></> }
                return (
                    <CompetitionCard
                        competition={competition}
                        company={company}
                        competition_type={competition_type}
                        competition_result_type={competition_result_type}
                        onCompetitionSelect={onSelectCompetition}

                    />
                )
            case 'bracket_competition':
                const bracket_competition = bracket_competitions.find(bc => bc.bracket_competition_id == data.item.id);
                if(!bracket_competition){ return <></> }
                const crt = competition_result_types.find(ct => ct.competition_result_type_id == bracket_competition.competition_result_type_id);
                if(!crt){ return <></> }
                const bracket = brackets.find(b => b.bracket_id == bracket_competition.bracket_id);
                const league = leagues.find(l => l.league_id == bracket?.league_id);
                return (
                    <BracketCompetitionCard
                        bracket_competition={bracket_competition}
                        competition_result_type={crt}
                        company={company}
                        bracket={bracket}
                        league={league}
                        onCompetitionSelect={onSelectBracketCompetition}
                    />
                )
            case 'squares_competition':
                const squares_competition = squares_competitions.find(sc => sc.sq_comp_id == data.item.id);
                if(!squares_competition){ return <></> }
                const event = events.find(e => e.event_id == squares_competition.event_id);
                return (
                    <SquaresCompetitionCard
                        squares_competition={squares_competition}
                        onSelectCompetition={onSelectSquaresCompetition}
                        company={company}
                        event={event}
                    />
                )
            default: return <></>
        }
    }

    if(company_loading || !company){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    return (
        <View style={{ flex:1 }}>
        <View style={{ flex:1, backgroundColor:Colors.shades.black_faded }} onLayout={(ev) => {
            const { height, width } = ev.nativeEvent.layout
            setModuleSize({ width, height });
        }}>
            <View style={{ position:'absolute', top:0, left:0, right:0, alignItems:'center' }} nativeID='profile_image'>
                <Image
                    source={{ uri: company.company_image?.url }}
                    style={{ width: module_size.width, height: module_size.width, borderBottomRightRadius:50, borderBottomLeftRadius:50 }}
                    resizeMode='cover'
                />
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{ ...view_styles.section, marginTop:module_size.width - 150 }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1, marginRight:10 }}>
                            <Text theme='header'>{company.company_name}</Text>
                            <Text style={{ marginTop:4 }} theme='body_2'>{company.company_description}</Text>
                        </View>
                        <View style={{ backgroundColor:Colors.highlights.highlight500, padding:10, borderRadius:22 }}>
                            <Text size={10} weight='bold' color={Colors.shades.white} textAlign='center'>{company.type.toUpperCase()}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Company Members</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>The following are members of {company.company_name}</Text>
                        </View>
                        <View style={{ padding:10 }}>
                            <Text theme='header_2'>{company_members.length}</Text>
                        </View>
                    </View>
                    <View style={view_styles.section_body}>
                        <View style={{ ...view_styles.body_row }}>
                            <View style={{ flex:1 }}>
                                <FlatList
                                    key='company_members'
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    data={company_members}
                                    renderItem={renderMembers}
                                    keyExtractor={(item) => item.player_id.toString()}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                {podcasts.length > 0 ?
                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Podcasts</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>The following are podcasts hosted by {company.company_name}</Text>
                        </View>
                        <View style={{ padding:10 }}>
                            <Text theme='header_2'>{podcasts.length}</Text>
                        </View>
                    </View>
                    <View style={{ ...view_styles.section_body, padding:0 }}>
                        <View style={{ ...view_styles.body_row }}>
                            <View style={{ flex:1 }}>
                                <FlatList
                                    key='company_pods'
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    data={podcasts}
                                    renderItem={renderPodcasts}
                                    keyExtractor={(item) => item.podcast_id.toString()}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                :<></>}
                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Engage</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>Use the toggles below to see recent activity from {company.company_name}</Text>
                        </View>
                    </View>
                    <View style={{ ...view_styles.section_body, padding:0 }}>
                        <View style={{ ...view_styles.body_row, margin:10, marginLeft:20, marginRight:20, borderRadius:22, padding:1, backgroundColor:Colors.shades.shade100 }}>
                            <FlatList
                                key={'toggles'}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                data={['Comps', 'Feeds']}
                                renderItem={renderToggles}
                                keyExtractor={(item) => item}
                            />
                        </View>
                        {active_toggle == 'Comps' ?
                        <View>
                            {competition_loading ?
                            <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:20, alignSelf:'center' }} />
                            :<></>}
                            <FlatList
                                key={'comps_list'}
                                data={sorted_comps.sort((a,b) => a.start_time.unix() - b.start_time.unix())}
                                renderItem={renderComps}
                                keyExtractor={(item) => `${item.id}:${item.type}`}
                            />
                        </View>
                        :active_toggle == 'Feeds' ?
                        <View>
                            {feed_loading ?
                            <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:20, alignSelf:'center' }} />
                            :<></>}
                            <View style={{ flexDirection:'row', flexWrap:'wrap' }}>
                                {articles.sort((a,b) => moment(b.create_datetime).unix() - moment(a.create_datetime).unix()).map((a,i) => {
                                    return renderArticles({ item: a, index:i })
                                })}
                            </View>
                        </View>
                        :<></>}
                    </View>
                </View>
            </ScrollView>
            {onBack ?
            <TouchableOpacity 
                style={{ position:'absolute', top:padding_insets?.top ?? 0, left:0, ...view_styles.section, backgroundColor:Colors.shades.shade100, height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center' }}
                onPress={() => onBack()}>
                <Icons.ChevronIcon direction='left' color={Colors.brand.midnight} size={14} />
            </TouchableOpacity> 
            :<></>}
            {onShareCompany?
            <TouchableOpacity 
                style={{ position:'absolute', top:padding_insets?.top ?? 0, right:0, ...view_styles.section, backgroundColor:Colors.shades.shade100, height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center' }}
                onPress={() => onShareCompany(company)}>
                <Icons.ShareIcon color={Colors.brand.electric} size={14} />
            </TouchableOpacity> 
            :<></>}
        </View>
        </View>
    )
}

export default CompanyProfile