import axios from "axios";
import { APIOverrides } from "../../../ApiOverrides";
import type { PodcastEpisodesProps, PodcastProps, PublicPlayerProps } from "../../../types";

let SOCIAL_SVC_API = '';
let AUTH_SVC_API = '';

export { PodcastApi }

const PodcastApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPodcastById: async(podcast_id:string):Promise<PodcastProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/podcast/${podcast_id}`)
            return resp.data.podcast
        } catch (e) {
            return undefined
        }
    },
    getPodcastEpisodesByPodcast: async(podcast_id:string, offset:number):Promise<PodcastEpisodesProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/episodes/podcast/${podcast_id}?offset=${offset}`);
            return resp.data.podcast_episodes
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getPlayersByPlayerIds: async (player_ids?: string[]): Promise<PublicPlayerProps[]> => {
        if(!player_ids){ return [] }
        if (player_ids.length == 0) { return [] }
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
            return resp.data.players
        } catch (e) {
            console.log(e);
            return []
        }
    },
}
