import React, { useCallback, useMemo } from 'react';
import { View, TouchableOpacity } from 'react-native';
import type { AthleteProps, BestAvailableOrderProps, EventOrderStatProps, EventProps, MarketProps, MarketSideOptionProps, OrderProps, TradeProps } from "../../../types"
import { view_styles } from '../../../constants/styles';
import MarketButton from '../../components/MarketButton';
import { Icons, LinearDiagnal, Text } from '../../../Components';
import Colors from '../../../constants/colors';
import { MarketButtonHelpers } from '../MarketButton/api';
import { MarketComponentHelpers } from '../../api';
import { AthleteImage } from '../../../Components/Jerseys';

type AthleteMarketProps = {
    event:EventProps,
    event_type:'team'|'tournament'|'match',
    athlete:AthleteProps,
    hide_stat_banner?:boolean,
    width?:number,
    market:MarketProps,
    show_event?:boolean,
    hide_liquidity?:boolean,
    latest_trades:TradeProps[],
    best_available_orders:BestAvailableOrderProps[],
    event_order_stats:EventOrderStatProps[],
    default_price_view:'last_trade'|'best_available',
    onOrder:(order:OrderProps) => void,
    show_grades?:boolean,
    onView:(data:{event_id:string, event_type:string, market_id:string, side_type:'athlete', side_id:string}) => void,
    onTradeLongPress:(trade:TradeProps) => void
}

const AthleteMarket = ({ event, show_event, hide_liquidity, hide_stat_banner, width, athlete, market, event_order_stats, default_price_view, latest_trades, show_grades, best_available_orders, onOrder, onView, onTradeLongPress }:AthleteMarketProps) => {
    

    const { liquidity, open_order_count } = MarketComponentHelpers.getLiqudity(event_order_stats)
    const is_locked = useMemo(() => MarketComponentHelpers.isEventMarketLocked(event, market.market_id),[JSON.stringify(event.supported_markets)])
    
    const renderSides = useCallback((data:{ item:MarketSideOptionProps, index:number, market:MarketProps }) => {
        const ba_order = best_available_orders.find(o => o.market_id == market.market_id && o.side_type == 'athlete' && o.side_id == athlete.athlete_id && o.side == data.item.side && o.show);
        const side_details = MarketComponentHelpers.getTeamEventSideIds(event, market, data.item, athlete, undefined);
        if(!side_details){ return <></> }
        let trade = MarketButtonHelpers.getVisibleTrade(default_price_view, data.market, data.item, latest_trades, best_available_orders, side_details.side_id, side_details.reversed_side_id); 
        if(!trade){
            trade = MarketButtonHelpers.getTradeFromMarket(market, event.event_id, 'team', data.item.side, data.item.id_source, side_details.side_id, side_details.reversed_side_id);
        }
        return (
            <View style={{ margin:2, height:50, width:75 }}>
                <MarketButton
                    side_option={data.item}
                    market={data.market}
                    trade={trade}
                    show_grades={show_grades}
                    order={ba_order}
                    locked={is_locked}
                    onLongPress={onTradeLongPress}
                    onPress={(order) => {
                        const order_title = MarketComponentHelpers.getOrderTitleForTeamEvent(order, market, event, athlete);
                        if(!order_title){ return alert('Unable to process order') }
                        onOrder({ ...order, title: order_title })
                    }}
                />
            </View>
        )
    },[athlete.athlete_id, is_locked, market.market_id, JSON.stringify(event_order_stats), JSON.stringify(best_available_orders)])

    return (
        <View style={{ ...view_styles.section, flex:1, borderWidth:1, borderColor:Colors.shades.shade600, width }}>
            {!hide_stat_banner ?
             <LinearDiagnal 
                label={market.stat_label ?? market.type}
                label_size={14}
                style={{ borderTopLeftRadius:8, padding:5  }}
                left_color={Colors.incentive.gold}
                right_color={'#ebd197'}
            />
            :<></>}
            {show_event ?
            <View style={{ padding:5, backgroundColor:Colors.shades.shade100 }}>
                <Text size={12} color={Colors.brand.midnight} textAlign='center' weight='semibold'>{event.event_title}</Text>
            </View>
            :<></>}
            <View style={{ ...view_styles.section_body, flex:1, padding:0, justifyContent:'center', alignItems:'center' }}>
                <AthleteImage
                    athlete={athlete}
                    size={50}
                    number_size={12}
                />
                <View style={{ padding:10, paddingBottom:0 }}>
                    <Text weight='bold' size={14} color={Colors.brand.midnight} textAlign='center'>{athlete.first_name} {athlete.last_name}</Text>
                    <Text style={{ marginTop:3 }} color={Colors.brand.midnight} weight='regular' textAlign='center' size={12}>{athlete.team?`${athlete.team.abbr}`:''} ({athlete.position})</Text>
                </View>
            </View>
            <View nativeID='market_sides' style={{ flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }}>
                {market.side_options.sort((a,b) => {
                    let a_t = a.side == market.trade_side ? 1 : 0
                    let b_t = b.side == market.trade_side ? 1 : 0
                    return a_t - b_t
                }).map((so, index) => {
                    return renderSides({ item:so, index, market })
                })}
                
            </View>
            {!hide_liquidity ?
            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, flexDirection:'row', alignItems:'center', borderBottomLeftRadius:8, borderBottomRightRadius:8 }}>
                <TouchableOpacity style={{ flex:1, flexDirection:'row' }} onPress={() => {
                    onView({
                        event_id: event.event_id, 
                        event_type: 'team', 
                        market_id: market.market_id, 
                        side_type: 'athlete', 
                        side_id: athlete.athlete_id
                    });
                }}>
                    <View style={{ backgroundColor:Colors.shades.white, height:30, width:30, borderRadius:100, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                        <Icons.LiquidityIcon color={Colors.brand.midnight} size={30}/>
                    </View>
                    <View style={{ marginLeft:10 }}>
                        <Text theme='body_2'>Liquidity</Text>
                        <Text size={12} color={Colors.brand.electric} weight='semibold'>${liquidity.toFixed()} {open_order_count} Orders</Text>
                    </View>
                </TouchableOpacity>
            </View>
            :<></>}
        </View>
    )
}

export default React.memo(AthleteMarket, (left, right) => {
    if(JSON.stringify(left) != JSON.stringify(right)){ 
        return false 
    }
    return true
})