import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, ScrollView, TouchableOpacity, FlatList } from "react-native"
import type { PodcastEpisodesProps, PodcastProps, PublicPlayerProps } from '../../types';
import { PodcastApi } from './api';
import Colors from '../../constants/colors';
import { SocialPodcastHelpers } from '../api';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Text } from '../../Components';
import PodcastEpisodeCard from '../PodcastEpisodeCard';
import moment from 'moment-mini';
import PlayerCard from '../PlayerCard';

type PodcastModuleProps = {
    podcast_id:string,
    padding_insets?:{ top:number, bottom:number },
    onBack?:() => void,
    onShareEpisode?:(pd:PodcastEpisodesProps) => void,
    onSharePodcast?:(p:PodcastProps) => void,
    onSelectEpisode:(pe:PodcastEpisodesProps) => void
    onSelectPlayer?:(p:PublicPlayerProps) => void
}

const PodcastModule = ({ podcast_id, padding_insets, onSelectEpisode, onBack, onShareEpisode, onSharePodcast, onSelectPlayer }:PodcastModuleProps) => {
    const [ module_size, setModuleSize ] = useState({ height:700, width:330 });
    const [ pod_data, setPodData ] = useState<{
        loading:boolean,
        episodes_loading:boolean,
        podcast?:PodcastProps,
        podcast_episodes:PodcastEpisodesProps[],
        players:PublicPlayerProps[],
        episodes_offset:number
    }>({
        loading:false,
        episodes_loading: false,
        podcast_episodes:[],
        players:[],
        episodes_offset:0
    });
    const { loading, episodes_loading, podcast, podcast_episodes, episodes_offset, players } = pod_data;

    useEffect(() => {
        PodcastApi.setEnvironment();
        getPodDataFromServer(podcast_id, 0);
    },[podcast_id]);

    const getPodDataFromServer = async(id:string, offset:number) => {
        setPodData({ ...pod_data, loading:true });
        const pod = await PodcastApi.getPodcastById(id);
        const pod_ep = await PodcastApi.getPodcastEpisodesByPodcast(id, offset);
        const ps = await PodcastApi.getPlayersByPlayerIds(pod?.player_ids);
        setPodData({
            ...pod_data,
            loading: false,
            episodes_loading: false,
            podcast: pod,
            players:ps,
            podcast_episodes: pod_ep,
            episodes_offset:offset
        })
    }

    const getEpisdoesFromServer = async(id:string, offset:number) => {
        setPodData({ ...pod_data, episodes_loading: true });
        const pod_eps = await PodcastApi.getPodcastEpisodesByPodcast(id, offset);
        setPodData({
            ...pod_data,
            episodes_loading: false,
            podcast_episodes: pod_eps,
            episodes_offset: offset
        })
    }

    const renderPlayers = (data:{item:PublicPlayerProps, index:number}) => {
        return (
            <View style={{ padding:4 }}>
                <PlayerCard
                    player={data.item}
                    width={135}
                    show_follow
                    border
                    alignment='vertical'
                    onSelectPlayer={(p) => onSelectPlayer ? onSelectPlayer(p) : console.log('')}
                    onFollowingUpdate={(pf) => console.log(pf)}
                />
            </View>
        )
    }


    const renderEpsidoes = (data: { item:PodcastEpisodesProps, index:number }) => {
        const ep_width = (module_size.width - 60) / 2
        return (
            <View>
                <PodcastEpisodeCard 
                    podcast_episode={data.item}
                    width={ep_width}
                    onShare={onShareEpisode}
                    onSelectEpisode={onSelectEpisode}
                />
            </View>
        )
    }

    if(!podcast || loading){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator style={{ padding:20, alignSelf: 'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    return (
        <View style={{ flex:1 }}>
            <View style={{ flex:1, backgroundColor:Colors.shades.black_faded }} onLayout={(ev) => {
            const { height, width } = ev.nativeEvent.layout
            setModuleSize({ width, height });
        }}>
                <View style={{ position:'absolute', top:0, left:0, right:0 }} nativeID='profile_image'>
                    <Image
                        source={{ uri: SocialPodcastHelpers.getPodcastImage(podcast) }}
                        style={{ width: module_size.width, height: module_size.width, borderBottomRightRadius:50, borderBottomLeftRadius:50 }}
                        resizeMode='cover'
                    />
                    
                </View>
                <ScrollView style={{ flex:1 }}>
                    <View style={{ ...view_styles.section, marginTop:module_size.width - 150 }}>
                        <View style={{ ...view_styles.section_header }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>{podcast.title}</Text>
                                <Text style={{ marginTop:4 }} theme='body_2'>{podcast.description_override ?? podcast.description}</Text>
                            </View>
                            {onSharePodcast ?
                            <TouchableOpacity style={{ padding:5, marginLeft:5 }} onPress={() => onSharePodcast(podcast)}>
                                <Icons.ShareIcon size={14} color={Colors.brand.electric} />
                            </TouchableOpacity>
                            :<></>}
                        </View>
                    </View>
                    {players.length > 0 ?
                    <View style={{ ...view_styles.section }}>
                        <View style={{ ...view_styles.section_header }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Podcasters</Text>
                                <Text style={{ marginTop:3 }} theme='body_2'>Below are the players that put this podcast on! Check out their profiles</Text>
                            </View>
                        </View>
                        <View style={{ ...view_styles.section_body, padding:10 }}>
                            <FlatList
                                key='podcastors'
                                data={players}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                renderItem={renderPlayers}
                                keyExtractor={(item) => item.player_id.toString()}
                            />
                        </View>
                    </View>
                    :<></>}
                    <View style={{ ...view_styles.section }}>
                        <View style={{ ...view_styles.section_header }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Episodes</Text>
                                <Text style={{ marginTop:3 }} theme='body_2'>Select an episode below to listed to it!</Text>
                            </View>
                        </View>
                        <View style={{ ...view_styles.section_body, padding:0 }}>
                            <View style={{ ...view_styles.body_row }}>
                                {episodes_offset > 0 ?
                                <Button
                                    title='PREV'
                                    title_color={Colors.brand.electric}
                                    backgroundColor='transparent'
                                    onPress={() => getEpisdoesFromServer(podcast_id, episodes_offset - 1)}
                                />
                                :<View/>}
                                <View style={{ flex:1 }} />
                                <Button
                                    title='NEXT'
                                    title_color={Colors.brand.electric}
                                    backgroundColor='transparent'
                                    onPress={() => getEpisdoesFromServer(podcast_id, episodes_offset + 1)}
                                />
                            </View>
                            {episodes_loading ?
                            <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
                            :<></>}
                            <View style={{ flexDirection:'row', flexWrap: 'wrap' }}>
                                {podcast_episodes.sort((a,b) => moment(b.publish_date).unix() - moment(a.publish_date).unix()).map((e,i) => {
                                    return renderEpsidoes({ item:e, index:i })
                                })}
                            </View>
                        </View>
                    </View>
                </ScrollView>
                {onBack ?
                <TouchableOpacity 
                    style={{ position:'absolute', top:padding_insets?.top ?? 0, left:0, ...view_styles.section, backgroundColor:Colors.shades.shade100, height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center' }}
                    onPress={() => onBack()}>
                    <Icons.ChevronIcon direction='left' color={Colors.brand.midnight} size={14} />
                </TouchableOpacity> 
                :<></>}
            </View>
        </View>
    )
}

export default PodcastModule