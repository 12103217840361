import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, ScrollView, FlatList, TouchableOpacity, Linking } from 'react-native';
import type { OrderProps, PlayerFollowerProps, PlayerFollowerStatsProps, PlayerLinkProps, PublicPlayerProps } from '../../types';
import { SocialProfileApi } from './api';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Spring, Text } from '../../Components';
import PlayerCard from '../PlayerCard';
import PlayerFollowersList from './components/PlayerFollowersList';
import PostsList from './components/PostsList';
import SocialOrdersList from '../SocialOrdersList';

type PlayerProfileProps = {
    viewing_player_id:string,
    player_id?:string,
    height?:number,
    maxWidth:number,
    padding_insets?:{ top:number, bottom:number },
    onStartChat?:(player_id:string) => void,
    onHeadToHead?: (player_id:string) => void,
    onFadeOrder: (order:OrderProps) => void,
    onCopyOrder:(order:OrderProps) => void,
    onShareProfile?:(player_id:string) => void,
    onSharePost?:(post_id:string) => void,
    onViewPostComments:(post_id:string) => void,
    onSelectPlayer:(player_id:string) => void,
    onRequestAuthenticate:() => void,
    onEditProfile:() => void,
    onBack?:() => void
}

const PlayerProfile = ({ viewing_player_id, maxWidth, padding_insets, player_id, height, onStartChat, onHeadToHead, onCopyOrder, onFadeOrder, onViewPostComments, onEditProfile, onSelectPlayer, onRequestAuthenticate, onBack }:PlayerProfileProps) => {
    const [ module_size, setModuleSize ] = useState({ height:755, width:330 });
    const [ show_following, setShowFollowing ] = useState<{
        visible:boolean,
        status: 'following'|'followers'
    }>({
        visible: false,
        status: 'following'
    })
    const [ my_data, setMyData ] = useState<{
        me_loading:boolean,
        player_follower?:PlayerFollowerProps
    }>({
        me_loading:false
    });
    const { player_follower } = my_data;
    
    const [ activity_data, setActivityData ] = useState<{
        activity_loading:boolean,
        orders:OrderProps[],
        activity_offset:number
    }>({
        activity_loading: false,
        orders:[],
        activity_offset: 0
    });
    const { orders } = activity_data;

    const [ follow_data, setFollowData ] = useState<{
        follow_loading:boolean,
        player_followers:PlayerFollowerProps[],
        players_following:PlayerFollowerProps[]
    }>({
        follow_loading: false,
        player_followers: [],
        players_following: []
    });
    const { follow_loading, player_followers, players_following } = follow_data;
    const top_3_followers = player_followers.slice(0, 4);
    const top_3_following = players_following.slice(0,4);
    const [ profile_data, setData ] = useState<{
        loading:boolean,
        player?:PublicPlayerProps,
        player_links:PlayerLinkProps[],
        active_toggle:string,
        player_follower_stats?:PlayerFollowerStatsProps
    }>({
        loading:false,
        player_links:[],
        active_toggle:'Activity'
    });
    const { player, active_toggle, player_links, player_follower_stats } = profile_data;

    useEffect(() => {
        SocialProfileApi.setEnvironment()
        getDataFromServer()
        getFollowDataFromServer();
    },[]);

    useEffect(() => {
        if(!player_id){ return }
        getMyDataFromServer(player_id)
    },[player_id])

    useEffect(() => {
        switch(active_toggle){
            case 'Activity':
                getOrderData(0);
                return
            default: return
        }
    },[active_toggle])

    const getOrderData = async(offset:number) => {
        setActivityData({ ...activity_data, activity_loading: true });
        const os = await SocialProfileApi.getOrdersByPlayerId(viewing_player_id, offset)
        setActivityData({
            ...activity_data,
            activity_loading: false,
            orders: os,
            activity_offset: offset
        })
    }

    const getDataFromServer = async() => {
        setData({ ...profile_data, loading:true });
        const p = await SocialProfileApi.getPlayerById(viewing_player_id)
        const pl = await SocialProfileApi.getPlayerLinksByPlayerId(viewing_player_id);
        const pfs = await SocialProfileApi.getFollowerStats(viewing_player_id);
        setData({
            ...profile_data,
            loading:false,
            player:p,
            player_links:pl,
            player_follower_stats: pfs
        })
    }

    const getFollowDataFromServer = async() => {
        setFollowData({ ...follow_data, follow_loading: true });
        const followers = await SocialProfileApi.getPlayerFollowersByPlayerId(viewing_player_id, 'followers', 0);
        const following = await SocialProfileApi.getPlayerFollowersByPlayerId(viewing_player_id, 'following', 0);

        setFollowData({
            ...follow_data,
            follow_loading: false,
            player_followers: followers,
            players_following: following
        })
    }

    const getMyDataFromServer = async(player_id:string) => {
        setMyData({ ...my_data, me_loading:true });
        const pf = await SocialProfileApi.getFollowerStatus(player_id, viewing_player_id);
        setMyData({
            ...my_data,
            me_loading: false,
            player_follower: pf
        })
    }

    const renderToggles = (data:{ item:string, index:number }) => {
        const active = data.item == active_toggle ? true : false
        return (
            <TouchableOpacity style={{ width: (module_size.width - 65) / 2, padding:15, borderRadius:22, backgroundColor:active?Colors.brand.midnight:'transparent' }}
            onPress={() => setData({ ...profile_data, active_toggle: data.item })}>
                <Text textAlign='center' weight='bold' size={14} color={active ?Colors.shades.white:Colors.brand.midnight}>{data.item}</Text>
            </TouchableOpacity>
        )
    }
    const renderFollowers = (data: { item:PlayerFollowerProps, index:number }) => {
        if(!data.item.playerDetails){ return <></> }
        return (
            <View style={{ padding:4 }}>
                <PlayerCard
                    player={data.item.playerDetails}
                    width={120}
                    border
                    alignment='vertical'
                    onSelectPlayer={(p) => onSelectPlayer(p.player_id)}
                    onFollowingUpdate={(pf) => console.log(pf)}
                />
            </View>
        )
    }


    const renderLinks = (data: { item:PlayerLinkProps, index:number }) => {
        return (
            <TouchableOpacity style={{ marginLeft:5, marginRight:5 }} onPress={() => Linking.openURL(data.item.link_full)}>
                {data.item.link_type == 'twitter' ?
                <Icons.TwitterIcon 
                    size={30}
                />
                :data.item.link_type == 'custom' ?
                <Icons.LinkIcon
                    color={Colors.brand.midnight}
                    size={30}
                />
                :data.item.link_type == 'linked_in' ?
                <Icons.LinkedInIcon
                    size={30}
                />
                :<></>}
            </TouchableOpacity>
        )
    }
        
    if(!player){
        return (
            <ActivityIndicator
                size='large'
                style={{ padding:20, alignSelf:'center' }}
                color={Colors.brand.midnight}
            />
        )
    }

    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.black_faded, maxWidth }} onLayout={(ev) => {
            const { height, width } = ev.nativeEvent.layout
            setModuleSize({ width, height });
        }}>
            <View style={{ position:'absolute', top:0, left:0, right:0 }} nativeID='profile_image'>
                <Image
                    source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ width: module_size.width, height: module_size.width, borderBottomRightRadius:50, borderBottomLeftRadius:50 }}
                    resizeMode='cover'
                />
                
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{ ...view_styles.section, marginTop:module_size.width - 150 }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>{player.first_name} {player.last_name}</Text>
                            <Text style={{ marginTop:4 }} theme='body_2'>@{player.username}</Text>
                        </View>
                        {player_id && player_id != viewing_player_id ?
                        <Button
                            title={player_follower?.status == 'following' ? 'UNFOLLOW' : 'FOLLOW'}
                            backgroundColor={player_follower?.status == 'following' ? Colors.shades.white : Colors.brand.electric}
                            title_color={player_follower?.status == 'following' ? Colors.brand.electric : Colors.shades.white}
                            borderWidth={player_follower?.status == 'following' ? 1 : 0}
                            borderColor={player_follower?.status == 'following' ? Colors.brand.electric: 'transparent'}
                            onPress={() => console.log('FOLLOW')}
                        />
                        :player_id && player_id == viewing_player_id ?
                        <Button
                            title='Edit Profile'
                            title_color={Colors.brand.electric}
                            backgroundColor={Colors.shades.white}
                            borderWidth={1}
                            borderColor={Colors.brand.electric}
                            onPress={() => onEditProfile()}
                        />
                        :<></>}
                    </View>
                    <View style={{ padding:10, backgroundColor:Colors.shades.shade100 }}>
                        <Text theme='body'>{player.bio && player.bio != '' ? player.bio : `${player.username} has not set a bio.`}</Text>
                    </View>
                    <View style={{ ...view_styles.section_header, borderBottomWidth:0 }}>
                        <Text theme='header_2' style={{flex:1}}>Engage With @{player.username}</Text>
                    </View>
                    <View style={{ padding:5, paddingLeft:20, paddingRight:20, paddingTop:0 }}>
                        {onStartChat ?
                        <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onStartChat(viewing_player_id)}>
                            <Text style={{ flex:1 }} theme='body'>Start a Chat</Text>
                            <View style={{ height:30, width:30, borderRadius:4, backgroundColor:Colors.brand.electric, justifyContent:'center', alignItems:'center' }}>
                                <Icons.ChatIcon size={22} color={Colors.shades.white} />
                            </View>
                        </TouchableOpacity>
                        :<></>}
                        {onHeadToHead ?
                        <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onHeadToHead(viewing_player_id)}>
                            <Text style={{ flex:1 }} theme='body'>Challenge Head to Head</Text>
                            <View style={{ height:30, width:30, borderRadius:4, backgroundColor:Colors.utility.error, justifyContent:'center', alignItems:'center' }}>
                                <Icons.HeadToHeadIcon size={22} color={Colors.shades.white} />
                            </View>
                        </TouchableOpacity>
                        :<></>}
                        {player_links.length > 0 ?
                        <View style={{ ...view_styles.body_row, padding:10, paddingRight:5 }}>
                            <View style={{ flex:1 }}>
                                <Text theme='body'>Checkout Socials</Text>
                            </View>
                            <View style={{ alignSelf:'flex-end' }}>
                                <FlatList
                                    data={player_links}
                                    renderItem={renderLinks}
                                    keyExtractor={(item) => item.player_link_id.toString()}
                                    horizontal
                                />
                            </View>
                        </View>
                        :<></>}
                    </View>
                    
                </View>
                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Followers</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>Who is follwoing @{player.username}</Text>
                        </View>
                        <View style={{ padding:10 }}>
                            <Text theme='header_2'>{player_follower_stats?.follower_count ?? 0}</Text>
                        </View>
                        <Button
                            title='View All'
                            backgroundColor={Colors.brand.midnight}
                            title_color={Colors.shades.white}
                            onPress={() => setShowFollowing({ visible:true, status: 'followers' })}
                        />
                    </View>
                    <View style={view_styles.section_body}>
                        <View style={{ ...view_styles.body_row }}>
                            {follow_loading ?
                            <ActivityIndicator size={'large'} color={Colors.brand.midnight} />
                            :<></>}
                            <View style={{ flex:1 }}>
                                <FlatList
                                    key='top_followers'
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    data={top_3_followers}
                                    renderItem={renderFollowers}
                                    keyExtractor={(item) => item.player_follower_id.toString()}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Following</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>Who is @{player.username} following</Text>
                        </View>
                        <View style={{ padding:10 }}>
                            <Text theme='header_2'>{player_follower_stats?.following_count ?? 0}</Text>
                        </View>
                        <Button
                            title='View All'
                            backgroundColor={Colors.brand.midnight}
                            title_color={Colors.shades.white}
                            onPress={() => setShowFollowing({ visible:true, status: 'following' })}
                        />
                    </View>
                    <View style={view_styles.section_body}>
                        <View style={{ ...view_styles.body_row }}>
                            {follow_loading ?
                            <ActivityIndicator size={'large'} color={Colors.brand.midnight} />
                            :<></>}
                            <View style={{ flex:1 }}>
                                <FlatList
                                    key='top_following'
                                    showsHorizontalScrollIndicator={false}
                                    horizontal
                                    data={top_3_following}
                                    renderItem={renderFollowers}
                                    keyExtractor={(item) => item.player_follower_id.toString()}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Activity</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>Use the toggles below to see recent activity from @{player.username}</Text>
                        </View>
                    </View>
                    <View style={{ ...view_styles.section_body, padding:0 }}>
                        <View style={{ ...view_styles.body_row, margin:10, marginLeft:20, marginRight:20, borderRadius:22, padding:1, backgroundColor:Colors.shades.shade100 }}>
                            <FlatList
                                key={'toggles'}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                data={['Posts','Activity']}
                                renderItem={renderToggles}
                                keyExtractor={(item) => item}

                            />
                        </View>
                        {active_toggle == 'Posts' ?
                        <PostsList
                            viewing_player={player}
                            player_id={player_id}
                            onRequestAuthenticate={onRequestAuthenticate}
                            onCopyOrder={onCopyOrder}
                            onFadeOrder={onFadeOrder}
                            onViewComments={onViewPostComments}
                        />
                        :active_toggle == 'Activity' ?
                        <SocialOrdersList
                            players={[player]}
                            orders={orders}
                            direction='vertical'
                            onCopyOrder={onCopyOrder}
                            onFadeOrder={onFadeOrder}
                        />
                        :<></>}
                        
                    </View>
                </View>
            </ScrollView>
            {onBack ?
            <TouchableOpacity 
                style={{ position:'absolute', top:padding_insets?.top ?? 0, left:0, ...view_styles.section, backgroundColor:Colors.shades.shade100, height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center' }}
                onPress={() => onBack()}>
                <Icons.ChevronIcon direction='left' color={Colors.brand.midnight} size={14} />
            </TouchableOpacity> 
            :<></>}
            {show_following.visible ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded }}>
                <Spring
                    to={0}
                    from={500}
                    slide='vertical'
                >
                    <PlayerFollowersList
                        height={height}
                        viewing_player={player}
                        player_id={player_id}
                        status={show_following.status}
                        total={show_following.status == 'followers' ? player_follower_stats?.follower_count : player_follower_stats?.following_count}
                        onClose={() => setShowFollowing({ ...show_following, visible:false })}
                    />
                </Spring>
            </View>
            :<></>}
        </View>
    )
}

export default PlayerProfile


/*
<Text theme='body'>Joined in {moment(player.create_datetime).format('MMM YYYY')}</Text>
*/